<template lang="pug">
  .user-manage
    .container
      .row.justify-content-md-center 
        .col-lg-6
          h1 メールアドレス変更
          p 変更後のメールアドレスを入力して「送信する」をクリックしてください。弊社よりメールアドレス変更に使用する「認証コード」を送信します。
          .form-group
            div 現在のメールアドレス
            div {{ oldmail }}
          .alert.alert-danger(v-if="message") {{ message }}
          .alert.alert-danger(v-if="errMessage") システムエラーが発生しました。
            div しばらく時間をおいてから再度お試しください。解決しない場合はお手数ですが
              router-link(to="/inquiry_form/input") こちら
              |よりをお問い合わせください。
          form.needs-validation(@submit.prevent="exec" novalidate)
            .form-group
              label(for="newmail") 変更後のメールアドレス
              input#newmail.form-control(type="text" placeholder="変更後のメールアドレス" v-model="mail" required)
              .invalid-feedback メールアドレスが正く入力されていません
            input.btn.btn-primary(type="submit" value="送信する" v-bind:disabled="isDisabled")
</template>

<script>
import axios from 'axios'

import {
    Config,
    CognitoIdentityCredentials
} from 'aws-sdk'
import {
    CognitoUserPool,
    CognitoUser,
    AuthenticationDetails,
    CognitoUserAttribute
} from 'amazon-cognito-identity-js'

export default {
  data() {
    return {
      oldmail: '',
      mail: '',
      message: '',
      isDisabled: false,
      errMessage: false,
    }
  },
  async created() {
    //サインインしていない場合
    this.$user.cognito.getUserAttributes((err, result) => {
      if (err) {
        this.$router.push({ name: 'login', query: { url:this.$route.path }}) //ページ移動
        return
      }
      //サインインしている場合、表示名の取得
      for (let i = 0; i < result.length; i++) {
        if (result[i].getName() == 'email') {
          this.oldmail = result[i].getValue()
        }
      }
    })
  },
  methods: {
    toHalfWidth(value) {
      return value.replace(/[Ａ-Ｚａ-ｚ０-９．＠＋－]/g, s => {
        return String.fromCharCode(s.charCodeAt(0) - 0xfee0)
      })
    },
    exec(e) {
      this.isDisabled = true

      this.message = ''

      this.errMessage = false

      this.mail = this.mail.trim()

      this.mail = this.toHalfWidth(this.mail)

      //入力チェック
      if(this.mail.length == 0){
        this.message = 'メールアドレスは空欄、又はスペースのみの入力はできません。' 
        this.isDisabled = false
        return
      }

      //エラー表示
      if (e.target.checkValidity() === false) {
        e.target.classList.add('was-validated')
        e.stopPropagation();
        this.isDisabled = false
        return
      }

      if(this.oldmail == this.mail.toLowerCase()) {
        this.message = 'メールアドレスが変更前と同じです'
        this.isDisabled = false
        return
      }

      //属性の更新          
      let attributeList = []

      let attributemail = {
        Name : 'email',
        Value : this.mail,
      }
      let  attributeMail = new CognitoUserAttribute(attributemail)
      attributeList.push(attributemail)

      this.$user.cognito.updateAttributes(attributeList, (err, result) => {
        if (err) {
          if(err.code == 'InvalidParameterException') {
            this.message = 'メールアドレスが正しく入力されていません。'
          }else if(err.code == 'TooManyRequestsException') {
            this.message = 'しばらく時間をおいてから再度お試しください。'
          }else if(err.code == 'AliasExistsException') {
            this.message = '既に登録されているメールアドレスです。'
          }else {
            this.errMessage = true
          }
          this.isDisabled = false
          return
        } 
        //メールアドレス更新
        const url = process.env.VUE_APP_API_GATEWAY + '/api-user'
        const params = {
          mail: this.mail
        }
        //内容送信 
        axios.patch(url, params, {
          headers: {
            'Authorization': this.$user.idToken
          }
        })
          .then(res => {
            this.isDisabled = false
            this.$router.push('/user/mail/input_auth')
          })
          .catch(err => {
            console.log(err)
          })
      })   
    }
  }
}
</script>